import { Link, useNavigate } from "react-router-dom";
import { signOut } from "firebase/auth";
import auth from "../FirebaseSetup/firebase";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

export default function LoggedInContent() {
  const navigate = useNavigate();
  const handleLogout = async () => {
    try {
      await signOut(auth);
      console.log("User signed out!");
      navigate("/"); // redirect to home or login page
      //warning logged out

      toast.success("Successfully logged out", {
        position: "top-center",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        draggable: true,
      });
    } catch (error) {
      console.error("Error signing out:", error);
      toast.error("Error signing out", {
        position: "top-center",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        draggable: true,
      });
    }
  };

  return (
    <div className="w-full">
      <div className="w-full bg-slate-900 flex flex-col md:flex-row gap-3 justify-center items-center p-2">
        <p className="text-white flex-1">
          Welcome to EEA Advisory, Please schedule the appointment using the
          below form. For general enquiries please call us at{" "}
          <a href="tel:0733992300">07 3399 2300</a>
        </p>
        <div className="flex flex-row gap-2 justify-center">
          <Link target="_blank" to="https://eea-advisory.com.au">
            <button className="px-4 text-sm py-2 font-bold text-white bg-gradient-to-r from-[#0E2733] to-[#2D4447]  border border-blue-300 rounded hover:bg-blue-700">
              Return to Website
            </button>
          </Link>
          <div>
            <button
              onClick={handleLogout}
              className="px-4 py-2  text-sm font-bold text-white bg-gradient-to-r from-[#0E2733] to-[#2D4447] border border-blue-300 rounded border-sm hover:bg-blue-700"
            >
              Sign Out
            </button>
          </div>
        </div>
      </div>
      {/* <div className="w-full text-center h-[600px] m-auto flex justify-center items-center">
        <h1 className="text-2xl">
          Pending Action - Microsoft Collect the integration code
        </h1>
      </div> */}

      <iframe
        title="EEA Advisory"
        className="w-full min-h-screen"
        src="https://outlook.office365.com/owa/calendar/ClientBookingsCopy@eea-advisory.com.au/bookings/"
      ></iframe>
      <ToastContainer />
    </div>
  );
}
