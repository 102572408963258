import auth from "../FirebaseSetup/firebase";
import { useAuthState } from "react-firebase-hooks/auth";
import GetStarted from "../InnerComponents/GetStarted";
import LoggedInContent from "../InnerComponents/LoggedInContent";
import PhoneVerificationComponent from "../InnerComponents/PhoneVerificationComponent";

const Dashboard = () => {
  const [user, loading] = useAuthState(auth);

  // console.log("user", user);
  // console.log("loading", loading);
  // console.log("error", error);

  return (
    <div>
      {loading ? (
        <div className="w-full h-full flex justify-center items-center p-36">
          <div
            className="inline-block h-20 w-20 animate-spin rounded-full border-4 border-solid border-current border-r-transparent align-[-0.125em] text-primary motion-reduce:animate-[spin_1.5s_linear_infinite]"
            role="status"
          >
            <span className="!absolute !-m-px !h-px !w-px !overflow-hidden !whitespace-nowrap !border-0 !p-0 ![clip:rect(0,0,0,0)]">
              Loading...
            </span>
          </div>
        </div>
      ) : (
        <>
          {user ? (
            user.phoneNumber ? (
              <LoggedInContent />
            ) : (
              <PhoneVerificationComponent />
            )
          ) : (
            <>
              <GetStarted />
            </>
          )}
        </>
      )}
    </div>
  );
};

export default Dashboard;
