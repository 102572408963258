import { useState } from "react";
import auth from "../FirebaseSetup/firebase"; // Use the already imported auth
import {
  signInWithPhoneNumber,
  RecaptchaVerifier,
  ConfirmationResult,
} from "firebase/auth";

import ReactPhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";

import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import Loader from "./Loader";

function PhoneVerificationComponent() {
  const [phoneNumber, setPhoneNumber] = useState("");
  const [verificationCode, setVerificationCode] = useState("");
  const [confirmationResult, setConfirmationResult] =
    useState<ConfirmationResult | null>(null);

  const [loading, setLoading] = useState(false);

  const handleSendCode = async () => {
    setLoading(true);

    let sanitizedPhoneNumber = phoneNumber.replace(/\D/g, "");

    // Ensure the phone number starts with '+'
    if (!sanitizedPhoneNumber.startsWith("+")) {
      sanitizedPhoneNumber = "+" + sanitizedPhoneNumber;
    }

    const recaptchaVerifier = new RecaptchaVerifier(
      auth,
      "recaptcha-container",
      {
        size: "invisible",
      }
    ); // Use the imported auth here

    console.log(phoneNumber);

    const result = await signInWithPhoneNumber(
      auth,
      sanitizedPhoneNumber,
      recaptchaVerifier
    ); // Use the imported auth here
    setConfirmationResult(result);

    setLoading(false);
  };

  const handleVerifyCode = async () => {
    if (confirmationResult) {
      try {
        await confirmationResult.confirm(verificationCode);
        console.log("Successfully verified code");
        toast.success("Successfully verified code", {
          position: toast.POSITION.TOP_RIGHT,
        });
        // Phone number is verified at this point
      } catch (error) {
        console.error("Error verifying code:", error);
        toast.error("Error verifying code", {
          position: toast.POSITION.TOP_RIGHT,
        });
      }
    }
  };

  return (
    <div className="min-h-screen  flex items-center justify-center py-12 px-4 sm:px-6 lg:px-8">
      {loading ? (
        <Loader />
      ) : (
        <div className="max-w-md w-full bg-white p-6 rounded-xl shadow-md space-y-8">
          <div className="text-center">
            <h2 className="mt-6 text-3xl font-extrabold text-gray-900">
              {confirmationResult
                ? "Verify your Phone Number"
                : "Enter your Phone Number"}
            </h2>
            <p className="mt-2 text-sm text-gray-600">
              {confirmationResult
                ? "Please enter the verification code you received."
                : "We will send a verification code to this number."}
            </p>
          </div>
          {confirmationResult ? (
            <div>
              <input
                type="text"
                placeholder="Verification code"
                value={verificationCode}
                onChange={(e) => setVerificationCode(e.target.value)}
                className="w-full px-3 py-2 border border-gray-300 rounded-md placeholder-gray-500 focus:ring-2 focus:ring-indigo-600 focus:border-transparent"
              />
              <button
                onClick={handleVerifyCode}
                className="mt-4 w-full flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
              >
                Verify Code
              </button>
            </div>
          ) : (
            <div className="flex justify-center items-center flex-col">
              <div>
                <ReactPhoneInput
                  country={"au"}
                  onlyCountries={["au"]}
                  placeholder="Enter phone number"
                  value={phoneNumber}
                  onChange={setPhoneNumber}
                  inputClass="px-3 py-2 border border-gray-300 rounded-md placeholder-gray-500 focus:ring-2 focus:ring-indigo-600 focus:border-transparent"
                />
              </div>
              <button
                onClick={handleSendCode}
                className="mt-4 flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
              >
                Send Code
              </button>
              {/* Invisible recaptcha container */}
            </div>
          )}
        </div>
      )}
      <div id="recaptcha-container" className="invisible"></div>
      <ToastContainer />
    </div>
  );
}

export default PhoneVerificationComponent;
