import { useState, useEffect } from "react";

import { useNavigate } from "react-router-dom";
import { useAuthState } from "react-firebase-hooks/auth";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import auth from "../FirebaseSetup/firebase";
import {
  sendSignInLinkToEmail,
  isSignInWithEmailLink,
  signInWithEmailLink,
} from "firebase/auth";

export default function Login() {
  const [user] = useAuthState(auth);
  const [email, setEmail] = useState("");
  const [loginLoading, setLoginLoading] = useState(false);
  const [loginError, setLoginError] = useState("");
  const [infoMsg, setInfoMsg] = useState("");

  const [initialLoading, setInitialLoading] = useState(false);
  const [initialError, setInitialError] = useState("");
  const [emailError, setEmailError] = useState("");
  const [isButtonDisabled, setButtonDisabled] = useState(true);

  const [formSubmitted, setFormSubmitted] = useState(false);

  const navigate = useNavigate();

  // const location = useLocation();
  // const { search } = location;

  const isValidEmail = (email: string) => {
    // Using a basic regular expression to validate the email format
    const regex = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,6}$/;
    return regex.test(email);
  };

  useEffect(() => {
    if (user) {
      navigate("/"); // redirect to home or login page
    } else {
      // user is not signed in but the link is a valid sign-in link
      if (isSignInWithEmailLink(auth, window.location.href)) {
        let email = window.localStorage.getItem("emailForSignIn");
        if (!email) {
          email = window.prompt("Please provide your email for confirmation");
        }

        setInitialLoading(true);
        signInWithEmailLink(auth, email!, window.location.href)
          .then((result) => {
            console.log("in results:", result.user);
            window.localStorage.removeItem("emailForSignIn");
            console.log("result", result);
            setInitialLoading(false);
            navigate("/");
          })
          .catch((error) => {
            console.log("error", error);
            setInitialLoading(false);
            setInitialError(error.message);
            navigate("/login");

            toast.error(error.message, {
              position: "top-center",
              autoClose: 3000,
              hideProgressBar: false,
              closeOnClick: true,
              draggable: true,
            });
          });
      } else {
        console.log("not a valid sign in link, enter email and sign in");

        setInitialLoading(false);
      }
    }
  }, [user, navigate]);

  const handleSignIn = async (e: any) => {
    e.preventDefault();
    if (!isValidEmail(email)) {
      setEmailError("Please enter a valid email address.");
      return;
    }
    setLoginLoading(true);
    try {
      sendSignInLinkToEmail(auth, email, {
        url: "https://appointments.eea-advisory.com.au/login",
        handleCodeInApp: true,
      }).then(() => {
        window.localStorage.setItem("emailForSignIn", email);
        setLoginLoading(false);
        setLoginError("");
        setInfoMsg("Please check your email for the sign-in link.");
        setFormSubmitted(true); // set form submission state
      });
    } catch (error: any) {
      console.log(error);
      setLoginLoading(false);
      setLoginError(error.message);

      toast.error(error.message, {
        position: "top-center",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        draggable: true,
      });
    }
  };

  const resetForm = () => {
    setFormSubmitted(false);
    setEmail("");
    setEmailError("");
    setInfoMsg("");
  };

  const handleEmailChange = (e: any) => {
    setEmail(e.target.value);
    setButtonDisabled(!isValidEmail(e.target.value));
  };

  return (
    <>
      <div className="h-[100vh] bg-white">
        {initialLoading ? (
          <div className="w-full h-full flex justify-center items-center p-36">
            <div
              className="inline-block h-20 w-20 animate-spin rounded-full border-4 border-solid border-current border-r-transparent align-[-0.125em] text-primary motion-reduce:animate-[spin_1.5s_linear_infinite]"
              role="status"
            >
              <span className="!absolute !-m-px !h-px !w-px !overflow-hidden !whitespace-nowrap !border-0 !p-0 ![clip:rect(0,0,0,0)]">
                Loading...
              </span>
            </div>
          </div>
        ) : formSubmitted ? ( // This is where you check if the form has been submitted
          <div className="flex flex-col justify-center items-center w-full h-full p-36">
            <p className="text-3xl mb-4 font-bold">{infoMsg}</p>
            <p className="text-green-700 text-2xl mb-4">
              Email entered is {email}
            </p>
            <p>or</p>
            <br></br>
            <button
              className="rounded-md bg-indigo-600 px-3.5 py-2.5 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
              onClick={resetForm}
            >
              Enter a different email
            </button>
          </div>
        ) : (
          <>
            {initialError !== "" ? (
              <div>{initialError}</div>
            ) : (
              <>
                {user ? (
                  <div>Plese Wait</div>
                ) : (
                  <>
                    <div className="flex min-h-full flex-1 flex-col justify-center px-6 py-12 lg:px-8">
                      <div className="sm:mx-auto sm:w-full sm:max-w-sm">
                        <p className="mt-2 text-center text-sm text-gray-600 max-w italic">
                          "In the intricate dance of economics, understanding
                          one's finances isn't merely about counting pennies and
                          dimes. It's about building a relationship with your
                          future, where foresight meets decision-making and
                          where ambition embraces prudence. As Benjamin Franklin
                          wisely noted, 'An investment in knowledge pays the
                          best interest.' In a world of endless financial
                          choices, educating oneself remains the paramount
                          strategy for securing long-term prosperity and
                          stability"
                        </p>
                        <h2 className="mt-10 text-center text-2xl font-bold leading-9 tracking-tight text-gray-900">
                          Schedule an Appointment
                        </h2>
                        <h3 className="mt-2 text-center text-sm text-gray-600 max-w font-bold">
                          We will send you a link to sign in
                        </h3>
                      </div>
                      <div className="mt-10 sm:mx-auto sm:w-full sm:max-w-sm">
                        <form className="space-y-6" onSubmit={handleSignIn}>
                          <div>
                            <label
                              htmlFor="email"
                              className="block text-sm font-medium leading-6 text-gray-900"
                            >
                              Email address
                            </label>
                            <div className="mt-2">
                              <input
                                id="email"
                                name="email"
                                type="email"
                                autoComplete="email"
                                required
                                className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                onChange={handleEmailChange}
                              />
                            </div>
                          </div>
                          <div>
                            <button
                              type="submit"
                              disabled={isButtonDisabled}
                              className={`flex w-full justify-center rounded-md px-3 py-1.5 text-sm font-semibold leading-6 shadow-sm ${
                                isButtonDisabled
                                  ? "bg-indigo-300 text-white cursor-not-allowed"
                                  : "bg-indigo-600 text-white hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                              }`}
                            >
                              {loginLoading ? (
                                <span>Logging you in</span>
                              ) : (
                                <span>
                                  Send me the login link{" "}
                                  <span aria-hidden="true">&rarr;</span>
                                </span>
                              )}
                            </button>
                          </div>
                          {loginError && (
                            <span className="error">{loginError}</span>
                          )}
                          {infoMsg && (
                            <div className="info w-full text-green-800 text-center">
                              {infoMsg}
                            </div>
                          )}
                          {emailError && (
                            <div className="error text-red-500">
                              {emailError}
                            </div>
                          )}
                        </form>
                      </div>
                    </div>
                  </>
                )}
              </>
            )}
          </>
        )}
        <ToastContainer />
      </div>
    </>
  );
}
