import './App.css';
import { Routes, Route } from 'react-router-dom';
import Login from './Components/login';
import Dashboard from './Components/dashboard';


function App() {
  return (
    <>
    <Routes>
      <Route path="/" element={<Dashboard />} />
      <Route path="/login" element={<Login />} />
    </Routes>
    </>
  );
}

export default App;
