import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";

// TODO: Replace the following with your app's Firebase project configuration
const firebaseConfig = {
  apiKey: "AIzaSyAXvQ-2QlyYjafBcLcxzCFAVgtofdtXAXY",
  authDomain: "eea-advisory.firebaseapp.com",
  projectId: "eea-advisory",
  storageBucket: "eea-advisory.appspot.com",
  messagingSenderId: "78097204236",
  appId: "1:78097204236:web:e46e03781b41eaf627da29",
  measurementId: "G-0XYT9PR9KL",
};

const app = initializeApp(firebaseConfig);
const auth = getAuth(app);


export default auth;
